<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row mt-5">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Email Configurations Settings</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group label="SMTP Server*" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        autocomplete="new-password"
                                                        placeholder="SMTP Server"
                                                        v-model="$v.form.smtp_server.$model"
                                                        :state="$v.form.smtp_server.$dirty ? !$v.form.smtp_server.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.smtp_server.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group label="Port*" label-for="response">
                                                <b-form-input
                                                        type="text"
                                                        id="response"
                                                        name="response"
                                                        autocomplete="new-password"
                                                        placeholder="25 | 2525 | 465 | 587"
                                                        v-model="$v.form.port.$model"
                                                        :state="$v.form.port.$dirty ? !$v.form.port.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.port.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group label="Email From*" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        autocomplete="new-password"
                                                        placeholder="Email From"
                                                        v-model="$v.form.email_from.$model"
                                                        :state="$v.form.email_from.$dirty ? !$v.form.email_from.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.email_from.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.form.email_from.email"
                                                                         id="input-4-live-feedback">Enter a valid email.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group label="Email To*" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="question"
                                                        autocomplete="new-password"
                                                        placeholder="Email To"
                                                        v-model="$v.form.email_to.$model"
                                                        :state="$v.form.email_to.$dirty ? !$v.form.email_to.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.email_to.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.form.email_to.email"
                                                                         id="input-4-live-feedback">Enter a valid email.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group label="Username*" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="Username"
                                                        autocomplete="new-password"
                                                        placeholder="Username"
                                                        v-model="$v.form.user_name.$model"
                                                        :state="$v.form.user_name.$dirty ? !$v.form.user_name.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.user_name.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group label="Password*" label-for="question">
                                                <b-form-input
                                                        type="text"
                                                        id="question"
                                                        name="Password"
                                                        autocomplete="new-password"
                                                        placeholder="Password"
                                                        v-model="$v.form.password.$model"
                                                        :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                        aria-describedby="input-4-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                         id="input-4-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                                        <span>Test SMTP</span>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>
<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    smtp_server: '',
                    port: "",
                    email_from: "",
                    email_to: "",
                    user_name: "",
                    password: "",
                },
                isDisabled: false,
            };
        },
        validations: {
            form: {
                id: {},
                smtp_server: {
                    required,
                },
                port: {
                    required,
                },
                email_from: {
                    email,
                    required,
                },
                email_to: {
                    email,
                    required,
                },
                user_name: {
                    required,
                },
                password: {
                    required,
                },
            }
        },
        methods: {
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    this.isDisabled = false;
                    return;
                }
                this.isDisabled = true;
                API.addEditEmailConf(
                    this.form,
                    data => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.$v.form.$reset();
                    },
                    err => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        console.log(err);
                        this.$snotify.error(err, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    })
            },
            loadItems() {
                API.getEmailConfSettings(
                    data => {
                        this.form.smtp_server = data.data.smtp_server;
                        this.form.port = data.data.port;
                        this.form.email_from = data.data.email_from;
                        this.form.email_to = data.data.email_to;
                        this.form.user_name = data.data.user_name;
                        this.form.password = data.data.password;
                        // this.users = data
                    },
                    err => {
                    }
                )
            }
        }, computed: {},
        mounted() {
            this.loadItems();

        }
    }
</script>
